<template>
  <node
    title="发起"
    :is-root="true"
    :content="content"
    @selected="$emit('selected')"
    placeholder="添加抄送人"
    :header-bgc="headerBgc" 
    :show-error="showError"
    header-icon="el-icon-user-solid"
    />
</template>

<script>
import Node from './Node.vue'

export default {
  name: "RootNode",
  components: {Node},
  props:{
    config:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed:{
    content(){
      if (this.config.props.cc.recordsName){
        let texts = []
        // console.log('看看.config.props.cc里面有什么',this.config.props.cc);
        // this.config.props.cc.assignedUser.forEach(org => texts.push(org.dataName))
        // console.log(texts);
        // this.showError = false
        return this.config.props.cc.recordsName
      } else {
        return '设置归档基础信息'
      }
    },
    headerBgc() {
      if (this.$store.state.process.diagramMode === 'viewer') {
        return this.config.props.headerBgc
      } else {
        return 'linear-gradient(90deg, #E9393F 0%, #ED9DA0 100%)'
      }
    },
  },
  created(){
  },
  mounted(){
    // console.log('看看this.config.props.cc里面有什么',this.config.props.cc);
  },
  data() {
    return {
      showError: false, // 是否有报错
    }
  },
  methods: {
    // 校验数据配置的合法性
    validate(err){
      // console.log('开始节点',this.config.props)
      if(this.config.props.ccType && this.config.props.cc.assignedUser.length === 0) {
        this.showError = true
        err.push('未设置抄送人')
        return err
      }else {
        this.showError = false
      }
    },
  }
}
</script>

<style scoped>

</style>
